/* phone-number */
.form-control {
  background-color: #ffffff !important;
  height: 56px !important;
  width: 100% !important;
}
.form-control:focus {
  border: 2px solid #0a98cd;
}
.flag-dropdown {
  background-color: #f9f9f9 !important;
}
.container-phone-number {
  width: 100%;
  height: 76px;
}
.error-phone-number {
  margin: 2px 0 0 10px;
  font-size: 12px;
  color: #d32f2f;
}
